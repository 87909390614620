<section id="footer">
    <div class="container py-5" style="background:#fafafa">
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6 my-auto" style="text-align: center;">
                <h1>Contact Me</h1>
                <br>
                <a href="mailto:saifilmomin4@gmail.com">
                    <h5 style="color: #5222D0;">saifilmomin4@gmail.com</h5>
                </a>
                <br>
                <div>
                    <a href="https://github.com/saifil4" target="blank" class="text-center mr-4" style="display: inline-block;">
                        <i style="font-size: 30px;color: #24292e;" class="fab fa-github"></i>
                    </a>
                    <a href="https://www.linkedin.com/in/saifil-momin/" target="blank" class="text-center"
                        style="display: inline-block;">
                        <i style="font-size: 30px;color: #0966c2;" class="fab fa-linkedin"></i>
                    </a>
                </div>
            </div>
            <div class="col-md-3"></div>
        </div>
    </div>
</section>