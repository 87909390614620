<div>

    <section class="section" style="background: linear-gradient(-62deg, #FBAB7E, #F7CE68); padding-top: 8vh; padding-bottom: 8vh;">
        <div class="container">
            <div class="row" style="margin-bottom: 20px;">
                <div class="col-md-7">
                    <img src="../../../assets/images/wfc/woc-spash.png" class="img-fluid">
                </div>
                <div class="col-md-5 my-auto">
                    <h1 style="color: white;">
                        Wheel For Change
                    </h1>
                    <p style="color: white;">
                        An Investment Platform for Social Entrepreneurs
                    </p>
                </div>
            </div>
        </div>
    </section>



    <section>
        <div class="container">
            <div class="row  my-5">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <h3>About Project</h3>
                    <p>
                        Wheel for change was an platform for Social Entrepreneurs where Potential investors
                        could
                        find and invest in projects. The Client required a digital platform in which Social
                        entrepreneurs
                        could create their profiles for their projects and could be found by investors.
                    </p>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="row pt-3 pb-3">
                <div class="col-md-3"></div>
                <div class="col-md-2">
                    <h5>Client</h5>
                    <p>Ashraful Hasan</p>
                </div>
                <div class="col-md-2">
                    <h5>My Role</h5>
                    <p>Product Designer</p>
                </div>
                <div class="col-md-2">
                    <h5>Duration</h5>
                    <p>3 months</p>
                </div>
                <div class="col-md-3"></div>
            </div>
        </div>
    </section>
    <section>
        <div style="background-image: url(../../../assets/images/wfc/woc-pages.png);" class="woc-pages"></div>
    </section>




    <section>
        <div class="container  my-5">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8 narrow-container">
                    <h3>Challenges</h3>
                    <p>
                        Social Entrepreneurs had to input in a lot of information through a form and forms can be long,
                        confusing, and time-consuming. This could affect the experience of the user and drive him away
                        from the platform.<b> The challenge here is to design a form that would be easy to understand
                            and
                            fill.</b>
                    </p>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
    </section>


    <section>
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <img src="../../../assets/images/wfc/Persona1.PNG" class="img-fluid image-shadow mb-3">
                    <img src="../../../assets/images/wfc/persona2.PNG" class="img-fluid image-shadow">
                </div>
                <div class="col-md-6 my-auto">
                    <h3>User Research</h3>
                    <p>We intereviewed 6 people to understand thier problems.</p>
                    <p><b>Key Outcomes from personas</b></p>
                    <ul class="lists">
                        <li>Manage my team and distribute tasks</li>
                        <li>Meet other entrepreneurs who believe in the same cause as I do</li>
                        <li>Connect with investors with the same
                            social values as myself</li>
                        <li>Spend less time doing paperwork</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <section>
        <div class="container my-5">
            <div class="row">
                <div class="col-md-6">
                    <img src="../../../assets/images/wfc/userflow1.PNG" class="img-fluid image-shadow mb-3">
                    <img src="../../../assets/images/wfc/userflow2.PNG" class="img-fluid image-shadow">
                </div>
                <div class="col-md-6 my-auto">
                    <h3>User Flows</h3>
                    <p>We intereviewed 6 people to understand thier problems.</p>
                    <p><b>Key Outcomes from personas</b></p>
                    <ul class="lists">
                        <li>Manage my team and distribute tasks</li>
                        <li>Meet other entrepreneurs who believe in the same cause as I do</li>
                        <li>Connect with investors with the same
                            social values as myself</li>
                        <li>Spend less time doing paperwork</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>


    <section>
        <div class="container my-5">
            <div class="row">
                <div class="col-md-6">
                    <h4>Tasks</h4>
                    <img src="../../../assets/images/wfc/tasks.jpg" class="img-fluid image-shadow">
                </div>
                <div class="col-md-6 my-auto">
                    <p>
                        With the help of user flows we identified various tasks a social entrprenuer would perform and
                        also differentiated which part of the journey will be free and which one should be paid.
                    </p>
                </div>
            </div>
        </div>
    </section>


    <section class="section">
        <div class="container">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8 narrow-container">
                    <h3>Prototype</h3>
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <img src="../../../assets/images/wfc/typeforms.PNG" class="img-fluid image-shadow">
                </div>
                <div class="col-md-6 my-auto">
                    <h4>Designing Forms</h4>
                    <p>
                        We wanted our user to have an decluttered and simple experience while filling the forms. Hence
                        we decided to take some inspiration from <a target="_blank"
                            href="https://www.typeform.com/templates/t/volunteer-application/">Typeforms</a> which has
                        some great form designs. What we
                        Liked was that it was very focused and presented one question at a time which was important for
                        our purpose.
                    </p>

                </div>
            </div>
            <div class="row my-5">
                <div class="col-md-2"></div>
                <div class="col-md-8 narrow-container">
                    <h5> <i>However doing this complicated the navigation, as you now need to navigate to each and every
                            question and we needed a solution for it.</i></h5>
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <img src="../../../assets/images/wfc/Journey.gif" class="img-fluid image-shadow">
                </div>
                <div class="col-md-6 my-auto">
                    <h4>Adding nagation to forms</h4>
                    <p>
                        We wanted our user to have an decluttered and simple experience while filling the forms. Hence
                        we decided to take some inspiration from <a
                            href="https://www.typeform.com/templates/t/volunteer-application/">Typeforms</a> which has
                        some great form designs. What we
                        Liked was that it was very focused and presented one question at a time which was important for
                        our purpose.
                    </p>

                </div>
            </div>
        </div>
    </section>

    <section class="section">
        <div class="container">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8 narrow-container text-center">
                    <h4>Experience the full prototype by clicking below</h4>
                    <br>
                    <a target="_blank"
                        href="https://xd.adobe.com/view/593d9ed4-6e94-45a3-754f-838602755b1d-2f0c/?fullscreen">
                        <button type="button" class="btn btn-primary">View Prototype</button>
                    </a>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
    </section>

    <!-- <section class="section">
        <div class="container">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <h3>Conclusion</h3>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
    </section> -->
</div>