<div id="about-me" class="my-5">
  <section class="py-5" style="background: #5222d0">
    <div class="container">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <h3 style="color: white" class="mb-3 text-center">About Me</h3>
          <p style="color: white">
            Hi, I am Saifil, A Front-end developer based in Toronto. I have 4
            years of experience working with start-ups, building and delivering
            quality web products built in JavaScript and Frameworks like React
            and Angular. I love trying out new things and am constantly
            learning. Apart from my work, I love playing chess and ping pong and
            hold an interest in sustainable development, especially in the space
            of architecture.
          </p>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </section>

  <section class="my-5">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h3 class="mb-4 text-center">Recommendations</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <p class="quote-text mb-0">
            "You rarely come across standout talent like Saifil. He expertly
            filled the role of User Experience Design Intern at Impactrio for my
            company’s "Impactraction," formerly "Wheel for Change" team. He,
            with other collaborators, took the idea of the solution and
            designed, wire framed, created the MVP of the app. Not only did
            Saifil show initiative in building out our UI/UX architecture from
            scratch, but he also played a huge role in bringing innovative
            features from a design perspective."
          </p>
          <p class="quote-author">
            Ashraful Hasan - Founder and Lead Consultant at Impactrio
          </p>
        </div>
        <div class="col-md-4">
          <p class="quote-text mb-0">
            "Saifil is smart, creative and professional. He has helped shape the
            BPM-D App and has played a crucial role in designing the user
            interface. He is dedicated and a pleasure to work with. He has been
            helpful, energetic and dedicated to all the work assigned to him and
            helped shaping the BPM-D India team. I will be happy to recommend
            saifil to any organisation that appreciates creativity and needs
            design thinkers."
          </p>
          <p class="quote-author">Rakesh Gusain - Director at BPM-D</p>
        </div>
        <div class="col-md-4">
          <p class="quote-text mb-0">
            "Saifil is a great team player to work with. Brings Exciting and
            Innovative Ideas constantly in the team to drive any project he's a
            part of. Has quality skills in UI/UX with a good amount of
            experience in designing Web pages for our product. He's Smart and
            thinks at right pace in the right place. It was a pleasure to work
            with him in all the projects we were in together"
          </p>
          <p class="quote-author">
            Rehban Khatri - Technical Consultant at BPM-D
          </p>
        </div>
      </div>
    </div>
  </section>
</div>
