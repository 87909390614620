import { Component, OnInit } from '@angular/core';
import { FooterComponent } from '../../footer/footer.component';
@Component({
  selector: 'app-wheel-for-change',
  templateUrl: './wheel-for-change.component.html'
})
export class WheelForChangeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
