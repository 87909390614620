<section class="py-3">
    <div class="container">
        <div class="row">
            <div class="col-md-2"></div>
            <div *ngIf="IsHomePage" class="col-md-8 text-center">
                <h3 class="mb-3">Skills & Tools</h3>
            </div>
            <div *ngIf="!IsHomePage" class="col-md-8">
                <h5 class="mb-3">Tools and Technologies</h5>
            </div>
            <div class="col-md-2"></div>
        </div>
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <div class="skill mr-3 mb-3" *ngFor="let skill of Skills">
                    <img *ngIf="skill.Icon" alt="{{skill.Alt}}" class="mr-1" height="25px" width="25px" src="{{skill.Icon}}" />{{skill.Name}}
                </div>
            </div>
            <div class="col-md-2"></div>
        </div>
    </div>
</section>