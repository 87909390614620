<div style="background: #F3F4F7;">
    <section class="section">
        <div class="container my-5">
            <div class="row" style="margin-bottom: 20px;">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <img src="../../assets/images/plato/plato-cover-inside.png" class="img-fluid">
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>

    </section>

    <section class="section">
        <div class="container">
            <div class="row" style="margin-bottom: 20px;">
                <div class="col-md-2"></div>
                <div class="col-md-8 my-auto">
                    <h1>
                        Plato
                    </h1>
                    <p>
                        Plato is an web app that helps users find learning content and resources relevant to a specific
                        profession. It curates, recommends and provides links to already existing youtube videos based
                        on user preferences.
                    </p>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="row pt-3 pb-3">
                <div class="col-md-3"></div>
                <div class="col-md-2">
                    <h5>My Role</h5>
                    <p>Product Designer</p>
                </div>

                <div class="col-md-2">
                    <h5>Duration</h5>
                    <p>3 months</p>
                </div>
                <div class="col-md-2">
                    <h5>Responsibilities</h5>
                    <p>User Research<br>Visual Design<br>Product Design</p>
                </div>
                <div class="col-md-3"></div>
            </div>
        </div>
    </section>



    <section>
        <div class="container">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8 narrow-container px-5 py-3">
                    <h3>The Problem</h3>
                    <p>
                        Every day thousands of videos are uploaded on youtube, out of which some are related to
                        eLearning. Youtube might not be an eLearning platform but many people come to youtube to learn
                        skills like programming, design, etc. As a matter of fact, in 2018 86% of people said that they
                        use youtube to learn something in a survey.
                    </p>
                    <p>
                        However, Youtube is a video sharing platform, not a learning platform, and lacks some features
                        of it. Also since the non eLearning content on the platform is way more in quantity than
                        eLearning content it is more likely to be in suggested videos which have become a major source
                        of youtube views. This leads to challenges especially in the initial stages of a youtube career
                        where you need a certain amount of views to get monetized.
                    </p>
                </div>
                <div class="col-md-2"></div>
            </div>

            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8 narrow-container px-5 py-3">
                    <h3>The Solution</h3>
                    <p>
                        A platform that is primarily for learning content where youtube creators can embed their videos
                        on the platform and engage and have more chances of engaging with their target audience to
                        achieve more views.
                    </p>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
    </section>

    <!-- //https://www.wendyschorr.com/project-page-build-a-meal -->
    <section>
        <div class="container">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8 narrow-container px-5 py-3">
                    <h3>User Research</h3>
                    <img src="../../assets/images/plato/plato-persona-1.png" style="height: auto; width: 100%;">
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8 narrow-container px-5 py-3">
                    <img src="../../assets/images/plato/plato-persona-2.png" style="height: auto; width: 100%;">
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8 narrow-container px-5 py-3">
                    <!-- <p><b>Key Outcomes</b></p> -->
                    <ul>
                        <!-- <li>I want to manage my team and distribute tasks</li>
                        <li>I want to meet other entrepreneurs who believe in the same cause as I do</li>
                        <li>I want to trust those who are investing in my business</li>
                        <li>I want to connect with investors with the same
                            social values as myself</li>
                        <li>I want to spend less time doing paperwork</li>
                        <li>I want to meet other entrepreneurs who believe in the same cause as I do</li>
                        <li>I get frustrated when I have to do repetitive work for different project applications</li> -->
                    </ul>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
    </section>




    <section class="section">
        <div class="container">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8 narrow-container px-5 py-3">
                    <h3>User Journey Mapping</h3>
                    <img src="../../assets/images/plato/User Journey Mapping.jpg" style="height: auto; width: 100%;">
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
    </section>


    <section class="section">
        <div class="container">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8 narrow-container px-5 py-3">
                    <h3>Prototype</h3>
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8 narrow-container px-5 py-3">
                    <h5>Landing Page</h5>
                    <img src="../../assets/images/plato/plato1.png" class="img-fluid image-wrapper">
                    <h5>Career Preference Page</h5>
                    <img src="../../assets/images/plato/plato2.png" class="img-fluid image-wrapper">
                    <h5>Topic Preference Page</h5>
                    <img src="../../assets/images/plato/plato3.png" class="img-fluid image-wrapper">
                    <h5>Home Page</h5>
                    <img src="../../assets/images/plato/plato4.png" class="img-fluid image-wrapper">
                    <h5>Content Page</h5>
                    <img src="../../assets/images/plato/plato5.png" class="img-fluid image-wrapper">
                    <h5>Content Creation Page</h5>
                    <img src="../../assets/images/plato/plato6.png" class="img-fluid image-wrapper">
                    <h5>Content Creators Information Page</h5>
                    <img src="../../assets/images/plato/plato7.png" class="img-fluid image-wrapper">
                    <h5>Verification Message Page</h5>
                    <img src="../../assets/images/plato/plato8.png" class="img-fluid image-wrapper">
                    <h5>Dashboard Page</h5>
                    <img src="../../assets/images/plato/plato9.png" class="img-fluid image-wrapper">
                    <h5>Content Upload Page</h5>
                    <img src="../../assets/images/plato/plato10.png" class="img-fluid image-wrapper">
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
    </section>

    <section class="section">
        <div class="container">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8 narrow-container px-5 py-5 text-center">
                    <h4>Experience the full prototype by clicking below</h4>
                    <br>
                    <a target="_blank"
                        href="https://xd.adobe.com/view/2ba63984-dabe-4d6d-b709-0e9362fbff66-7ef5/?fullscreen">
                        <button type="button" class="btn btn-primary">View Prototype</button>
                    </a>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
    </section>

    <section class="section">
        <div class="container">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <h3>Conclusion</h3>
                    <p>
                        Overall I believe Plato would be able to solve the earlier defined user problems and make their
                        lives easier. However, a few things still need to be addressed, one of them is to focus more on
                        the content and give more information about it like the learning outcome, etc.
                    </p>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
    </section>
</div>
<footer>
    <app-footer></app-footer>
</footer>